#services{
    background-color: #f2f4fc;
    background-repeat: no-repeat  !important;
    background-position: bottom center !important;
  }

  #services h1{
    font-weight: 700;
    font-size: 48px !important;
  }
  #services h2{
    font-weight: 700;
    font-size: 34px !important;
  }
  #services h3{
    font-weight: 700;
    font-size: 24px;
    line-height:24px;
  }
  #services p{
    font-weight: 400;
    font-size: 18px;
    line-height:21px;
    
  }
  @media (min-width: 1200px) {
    #services h1{
        font-size: 58px !important;
      }
    }
  @media screen and (max-width: 770px) { 
    #services h1{
      font-size: 35px !important;
    }
    #services h2{
      font-size: 24px !important;
    }
    #services h3{
      font-size: 21px !important;
    }
    #services p{
      font-size: 17px;
      line-height:18px;
      
    }
    
  }
  /* ------------------------------------------ */
  #services .neutral-div{
    /* min-height: 400px; */
    padding: 50px 0px 50px 0px;
    /* background-color:#f2f4fc; */
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  #services .white-div{
    background-color:#fff;
  }
  /* ------------------------------------------ */
  #services .headboard{
    background-color:#594184;
    color:#fff !important;
    background-repeat: no-repeat  !important;
    background-position: top center !important;
    overflow: hidden !important;
    min-height:70% !important; 
    padding-bottom: 0px !important;
  }

  
  #services .headboard .texto{
    margin-top:80px  !important;
    margin-bottom:50px  !important;
  }

#headboard h3{
  color:#fff !important;
}
@media screen and (max-width: 770px) { 
    
    #services .headboard .texto{
      margin-top:40px  !important;
      margin-bottom:10px  !important;
    }
    #services .headboard{
      text-align: center !important;
      background-position: top right !important;
    }
  
  }
  #services .headCircle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    background-color: #BFB1D3;
    margin: 50px 0px 50px 0px;
    border-radius: 50%;
  }
  #services .headboard img{
    margin-top:75px;
  }

  @media (max-width: 1200px) {
    #services .headCircle{
      width: 300px;
      height: 300px;
      margin: 45px 0px 45px 0px;
    }
    
    }
    @media (min-width: 770px) and (max-width: 1090px) { 
      #services .headCircle{
        width: 250px;
        height: 250px;
        margin: 45px 0px 45px 0px;
      }

    }  

/* ------------------------------------------- */
#services .customCard{
  /* max-width: 890px; */
  margin: 20px 20px 20px 20px;
  padding: 10px 10px 10px 10px;
  background-color:#fff;
  color:#21005c;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
}
#services .customCard .texto{
  margin: 10px 0px 30px 0px;
}
/* ------------------------------------------- */
#services .customTitle{
  color:#21005c;
  padding: 35px 0px 35px 0px;
  text-align: center;
  margin: 0 auto;
}
/* ------------------------------------------- */
#services .titleCard{
  margin: 30px 20px 30px 30px;
  padding: 0px 5px 0px 5px;
  background-color:#bfb1d3;
  color:#fff;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
/* ------------------------------------------- */
#services .numberCustomCard{
  margin: 20px 20px 20px 50px;
  padding: 10px 10px 10px 10px;
  background-color:#fff;
  color:#21005c;
  min-height: 160px;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);

}
#services .numberCard{
  
  width: 100px;
  height: 100px;
  margin-left: -60px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  }
 
  @media screen and (max-width: 770px) { 
    #services .numberCustomCard{
      margin: 20px 10px 20px 30px;
      min-height: 90px;
      }
    #services .numberCard{
      
      width: 70px;
      height: 70px;
      margin-left: -50px;
      box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
      }
  } 
  /* --------------------------------------------- */
  #services .serviceIconCard{
    margin: 20px 20px 20px 20px;
    padding: 10px 10px 10px 10px;
    background-color:#fff;
    color:#21005c;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
  
  }
  #services .iconCard{
    background-color: #faf8ff;
    width: 100px;
    height: 100px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
    margin: -60px auto 20px auto;
    }
  #services .iconCard img{
    margin-left:-5px;
    margin-top:-5px;
  }
  /* --------------------------------------------- */
  #services .placaFinal{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    min-height: 500px;
    background-color: #fff;
    color:#21005c;
  } 
  