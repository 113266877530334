@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,700&display=swap');

h1,h2,h3,h4,h5,h6,p{
  font-family: 'Mulish', sans-serif;
}
.container-body{
  max-width: 1280px !important;
}
.container-body-2{
  max-width: 1000px !important;
}
.max-foot{
  max-width: 970px !important;
}
.base{
  min-height: 100%;
  background-color:#f7f5f9;
}
a:link, a:visited, a:active {
  text-decoration:none;
}

.navegacion{
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  min-height: 80px;
  flex-direction: row;
  box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2);
  z-index:999;
}
.button{
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
}
.footer{
  font-family: 'Mulish', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21005c;
  width: 100%;
  min-height: 100px;
  color:#fff !important;
  padding: 10px 0px 10px 0px;
  margin-top: -10px;
}
.footer p{
  font-weight: 300;
  font-size: 14px;
  line-height:15px;
}
.footer strong{
  font-weight: 700;
  font-size: 14px;
  line-height:15px;
}
.footer .block{
  padding: 20px 30px 20px 30px; 
}
.footer .block p{
  color:#fff !important;
}
.footer .block a{
  color: #bfb1d3
}
.icono{
  font-size:23px !important;
  color:#ffbad5 !important;
  padding:6px 2px 20px 2px !important;
}
.pie{
  background-color:#16003D;
  display:block;
  text-align:center;
  font-weight: 400;
  font-size:12px;
  color:#ffbad5 !important;
  position: relative;
  padding:20px 10px 10px 10px;
}
.pie p{
  font-weight: 300;
  font-size: 12px;
  line-height:15px;
  color:#bfb1d3  !important;
}
.b-solicitud{
  border:none !important;
  border-radius:12px !important;
  font-size:13px !important;
  padding: 8px 15px 8px 15px !important;
  font-weight:bold !important;
}
.b-positivo{
  background-color: #FD3685 !important;
  color:#fff !important;
}
.b-positivo:hover{
  background-color: #FF7BAF!important;
  color:#fff !important;
}
.b-negativo{
  background-color: #fff !important;
  color:#21005c !important;
}
.b-negativo:hover{
  background-color: #FFBAD5 !important;
  color:#21005c !important;
}
.b-sabermas{
  border:none !important;
  border-radius:12px !important;
  font-size:13px !important;
  padding: 8px 15px 8px 15px !important;
  font-weight:bold !important;
  background-color: #FD3685 !important;
  color:#fff !important;
}
.b-sabermas:hover{
  background-color: #FF7BAF!important;
  color:#fff !important;
}

.parrafo {
  white-space: pre-wrap;/*Reemplaza los \n por saltos de línea*/
}

@media screen and (max-width: 1030px) { 
  .parrafo {
    white-space: normal;/*Reemplaza los \n por saltos de línea*/
  }
  #terminos .parrafo {
    white-space: pre-wrap;/*Reemplaza los \n por saltos de línea*/
  }
}
/* ---------------------------------------- */
.b-custom{
  font-weight: 500 !important;
  border:none !important;
  border-radius:16px !important;
  font-size:17px !important;
  padding: 8px 15px 8px 15px !important;
  
}
.b-custom-pink{
  background-color: #FD3685 !important;
  color:#fff !important;
}
.b-custom-pink:hover{
  background-color: #FF7BAF!important;
  color:#fff !important;
}
.b-custom-blue{
  background-color: #594184 !important;
  color:#fff !important;
}
.b-custom-blue:hover{
  background-color: #341769!important;
  color:#fff !important;
}
.b-custom-white{
  background-color: #fff !important;
  color:#594184 !important;
}
.b-custom-white:hover{
  background-color: #a69eb4!important;
  color:#594184 !important;
}
/* ---------------------------------------- */
.notFound{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height:550px;
  text-align: center;
  color:#21005c !important;
  padding: 0px 20px 0px 20px;
}
.notFound img{
  margin-bottom: 50px;
}
/* ---------------------------------------- */
#terminos {
  padding: 0px 20px 0px 20px;
}
#terminos h2,p{
  color:#21005c;
}
#terminos p{
  font-weight: 400;
  font-size: 16px;
  line-height:18px;
}
/* ---------------------------------------- */
.faq-button{
  background-color: #bfb1d3 !important;
  color: #fff  !important;
  border:none !important;
  font-weight: 700 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.faq-body{
  background-color: #fff !important;
  color: #594184  !important;
  margin-top:-7px;
  padding: 30px 20px 20px 30px;
}
/* --------------------------------------------- */
#contracargos {
  color:#21005c;
}
#contracargos .serviceIconCard{
  margin: 20px 20px 20px 20px;
  padding: 10px 10px 10px 10px;
  background-color:#fff;
  color:#21005c;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);

}
#contracargos .iconCard{
  background-color: #faf8ff;
  width: 100px;
  height: 100px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  margin: -60px auto 20px auto;
  }
#contracargos .iconCard img{
  margin-left:-5px;
  margin-top:-5px;
}
/* --------------------------------------------- */
.contracargo-button{
  background-color: #fff !important;
  color: #594184  !important;
  border:none !important;
  font-weight: 700 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.contracargo-body{
  background-color: #fff !important;
  color: #594184  !important;
  margin-top:-7px;
  padding: 30px 20px 20px 30px;
}
/* --------------------------------------------- */
.errorMsj{
  font-weight: 700 !important;
  color:red  !important;
}
/* --------------------------------------------- */
#reclamos{
  background-color: #f2f4fc;
  background-repeat: no-repeat  !important;
  background-position: bottom center !important;
  color:#21005c;
}

#reclamos h1{
  font-weight: 700;
  font-size: 48px !important;
}
#reclamos h2{
  font-weight: 700;
  font-size: 34px !important;
}
#reclamos h3{
  font-weight: 700;
  font-size: 24px;
  line-height:24px;
}
#reclamos p{
  font-weight: 400;
  font-size: 18px;
  line-height:21px;
  
}
#pre-reclamos{
  color:#21005c;
} 
#pre-reclamos .botones{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
} 
@media (min-width: 1200px) {
  #reclamos h1{
      font-size: 58px !important;
    }
  }
@media screen and (max-width: 770px) { 
  #reclamos h1{
    font-size: 35px !important;
  }
  #reclamos h2{
    font-size: 24px !important;
  }
  #reclamos h3{
    font-size: 21px !important;
  }
  #reclamos p{
    font-size: 17px;
    line-height:18px;
    
  }
  
}
/* ------------------------------------------ */

.textoPagoSeguro{
  color:#21005c;
  padding:50px 20px;
  text-align:center;
}
.textoPagoSeguro p{
  font-size:22px;
  line-height:24px;
}
.discoMeta{
  padding:30px 30px 0;
  text-align:center;
  width:100%;
}
.discoMeta img{
  max-width:300px;
}
.textCard{
  background-color:#ff7baf!important;
  color:#fff!important;
  padding:10px 0;
}
.textCard p{
  color:#fff!important;
  font-size:22px;
  line-height:24px;
}
.textCardMargin1{
  margin:-130px 30px 30px;
}
.textCardMargin2{
  margin:30px;
}
.validacion-button{
  background-color:#ff7baf!important;
  border:none!important;
  color:#fff!important;
  font-weight:700!important;
  margin:0 30px;
  padding-bottom:10px!important;
  padding-top:10px!important;
}
.validacion-body{
  background-color:#f7f5f9!important;
  color:#ff7baf!important;
  margin-top:-7px;
  padding:30px 20px 20px 30px;
}
.seccion-min-centrado{
  align-items:center!important;
  display:flex!important;
  justify-content:center!important;
  margin-top:-70px;
  min-height:350px!important;
  overflow:hidden;
}
/* ------------------------------------------ */
.disputas h2,h3,h4,p{
  color:#21005c !important;
}
.disputas-button{
  color:#21005c !important;
  background-color: #fff !important;
  box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2);
  font-weight: 700 !important;
  border: none !important;
  min-height: 50px  !important;
}
.disputas-body{
  background-color: #fff !important;
  color: #594184  !important;
  margin-top:-7px;
  padding: 30px 20px 20px 30px;
}
/*---------------------------------------------*/
.integrate .form-label {
  color: #594184  !important;
}

.integrate .textColor {
  color: #594184  !important;
}

.integrate .btn{
  background-color: #fff !important;
  color:#ff3888 !important;
}

.integrateServices{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff !important;
  color:#fd5497 !important;
  min-height: 45px;
  border-radius: 15px;
}

.integrateHeadboard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color:#fd3685;
  color:#fff !important;
  overflow: hidden !important;
  min-height:450px !important; 
}


.integrateHeadboardColor {
  color:#fff !important;
}





