h1{
  font-weight: 700;
  font-size:40px;
}
h2{
  font-weight: 700;
  font-size: 34px !important;
}
h3{
  font-weight: 700;
  font-size:24px;
}
h5{
  font-weight: 700;
  font-size:20px;
}
p{
  font-weight: 400;
  font-size: 18px;
  line-height:21px;
}
/*---------------------------------------*/

.blue-back{
  background-color:#21005c;
  color:#fff;
  background-repeat: no-repeat  !important;
  background-position: top center !important;
  /* background-size: 100vw !important; */
}
.white-back{
  background-color:#fff;
  color:#21005c;
  background-repeat: no-repeat  !important;
  background-position: top center !important;
  /* background-size: 100vw !important; */
}
.cyan-back{
  background-color:#f2f4fc;
  color:#21005c;
  background-repeat: no-repeat  !important;
  background-position: top center !important;
  /* background-size: 100vw !important; */
}
/*---------------------------------------*/
.cabecera-texto{
  margin-top:80px  !important;
  margin-bottom:50px  !important;
}
.seccion-centrado{
  display:flex !important;
  min-height:529px !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden;
  }
.cont-centrado{
    text-align: center;
}
/*---------------------------------------*/
.rechazos-card{
  margin-top:200px;
}
.deco4{
    position: absolute;
    margin-top:-300px;
    width: 100%;
    max-width: 598px;
    padding: 0px 10px 0px 10px;
}
.card-4-principal{
  padding:50px 0px 50px 0px !important;
}
.card-4-principal p{
  margin-top: 10px;
  margin-bottom: 30px;
}
.card-4-secundaria-container{
  display:flex !important;
  justify-content: center !important;
  margin-bottom:50px;
}
.card-4-secundaria {
 display:flex!important;
 flex-direction: column;
 row-gap: 0px;
 justify-content: top !important;
 align-items: center;
 height: 250px;
}

.card-4-secundaria .icon-div{
  position: absolute;
  bottom:20px;
 }
.card-4-secundaria-container .card{
  margin-bottom:20px;
}
.card-4-secundaria-container .card p{
  font-size: 16px;
  line-height:17px;
}
/*-------------------------------------------------------------------*/
.card-5-principal{
  padding:30px 20px 0px 20px !important;
}
.card-5-secundaria{
  max-height:50px !important;
  margin-top:-10px !important;
}
.card-5-secundaria-container{
  display:flex !important;
  justify-content: center !important;
  margin-bottom:20px;
}
.card-5-secundaria-container .card{
  margin-bottom:20px;
}
.card-5-secundaria-container a{
 color:#21005c;
}
.card-5-secundaria-container a:hover{
  color:#086b18;
 }
/*-------------------------------------------------------------------*/
.div-servicios{
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: flex-start !important;
  padding-left:18px !important;
  border-left: 7px solid #FD3685 !important;
  margin-bottom: 60px !important;
}
.boton-servicios{
  position: absolute !important;
  bottom: 0px !important; 
}

@media screen and (min-width: 765px) and (max-width: 1300px) { 
  h1{
    font-size: 36px !important;
  }
  h3{
    font-size: 26px !important;
  }
  h5{
    font-size:18px;
  }
  p{
    font-size:16px;
  }
  .cabecera-inicio {
    padding-top:30px;
    min-height: 445px;
    min-width: 400px;
  }

  .card-5-secundaria{
    max-height: 100px !important;
    margin: -20px -35px 0px -35px !important;
    padding:10px 0px 0px 0px !important;
  }
  

 }
 @media screen and (max-width: 765px) {
  h1{
    font-size: 28px !important;
  }
  h3{
    font-size: 24px !important;
  }
  h5{
    font-size:16px;
  }
  p{
    font-size:14px;
  }
  .blue-back{
    text-align: center !important;
    background-position: top left !important;
  }
  
  .white-back{
    background-position: bottom left !important;
    padding-top:50px  !important;
    
  }
  .cyan-back{
    background-position: bottom left !important;
    padding-top:50px  !important;
    
  }
  .image-demo{
    margin-top:20px  !important;
    margin-bottom:20px  !important;
  }
  .card-4-principal{
    padding:0px 30px 50px 30px !important;
  }
  .card-4-secundaria-container{
    display:flex !important;
    justify-content: center !important;
    /* margin-bottom:50px; */
  }
  
  .card-4-secundaria {
   height: 240px;
  }
  .card-5-secundaria{
    max-height: 70px !important;
    margin: -20px -35px 0px -35px !important;
    padding:0px 0px 60px 0px !important;
  }
  
  .div-servicios{
    margin-top: 0px;
  }
  
  .div-container-servicios{
    margin-top:0px !important;
  }
  .deco4{
    padding: 80px 10px 0px 10px;
}
  

 } 

 @media screen and (max-width: 400px) {
  .card-5-secundaria-container .card p{
    font-size: 12.5px;
    line-height:13px;
  }
  .deco4{
    padding: 100px 10px 0px 10px;
}

 }
 /* ------------------------------------------ */


.headboard{
  background-color:#fff;
  color:#21005c;
  background-repeat: no-repeat  !important;
  background-position: top center !important;
  overflow: hidden !important;
  min-height:70% !important; 
  padding-bottom: 0px !important; 
  background-color:#21005c;
}

.headboard .texto{
  margin-top:80px  !important;
  margin-bottom:50px  !important;
}
@media screen and (max-width: 993px) { 

  .headboard .texto{
      margin-top:40px  !important;
      margin-bottom:10px  !important;
    }
  .headboard{
      text-align: left !important;
      background-position: top left !important;
    }
  .headboard h1{
      font-size: 28px !important;
    }
  .headboard h3{
      font-size: 20px !important;
    }  

  }  
  
  
@media screen and (max-width: 770px) { 

.headboard .texto{
    margin-top:40px  !important;
    margin-bottom:10px  !important;
  }
.headboard{
    text-align: center !important;
    background-position: top left !important;
    
    
  }

}

/* ------------------------------------------- */