/* SOLUTIONS -------------------------------------------------------------------------------------------------------------*/
/*Headboard*/
#solutions{
  background-color: #f2f4fc;
}
#solutions h1{
  font-weight: 700;
  font-size: 48px !important;
}
#solutions h2{
  font-weight: 700;
  font-size: 34px !important;
}
#solutions h3{
  font-weight: 700;
  font-size: 24px;
  line-height:24px;
}
#solutions p{
  font-weight: 400;
  font-size: 18px;
  line-height:21px;
  
}
@media (min-width: 1200px) {
  #solutions h1{
      font-size: 58px !important;
    }
  }
@media screen and (max-width: 770px) { 
  #solutions h1{
    font-size: 35px !important;
  }
  #solutions h2{
    font-size: 24px !important;
  }
  #solutions h3{
    font-size: 21px !important;
  }
  #solutions p{
    font-size: 17px;
    line-height:18px;
    
  }
  
}
/* ------------------------------------------ */
#solutions .cyan-back{
  background-color:#f2f4fc;
  color:#21005c;
  background-repeat: no-repeat  !important;
  background-position: top center !important;  /* background-size: 100vw !important; */
}
#solutions .white-back{
  background-color:#fff;
  color:#21005c;
  background-repeat: no-repeat  !important;
  background-position: top center !important;  /* background-size: 100vw !important; */
}


#solutions .neutral-div{
  /* min-height: 400px; */
  padding: 80px 0px 50px 0px;
  background-color:#f2f4fc;
  display:flex;
  flex-direction: column;
  justify-content: center;

}
#solutions .white-div{
  min-height: 580px;
  background-color:#fff;
  display:flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat  !important;
  background-position: bottom center !important;

}
@media screen and (max-width: 770px) { 
  #solutions .neutral-div{
    /* min-height: 400px; */
    padding: 30px 0px 30px 0px;
  }
  #solutions .white-back{
    background-position: top right !important;  /* background-size: 100vw !important; */
  }

}
/* ------------------------------------------ */


#solutions .headboard{
    background-color:#fff;
    color:#21005c;
    background-repeat: no-repeat  !important;
    background-position: top center !important;
    overflow: hidden !important;
    min-height:70% !important; 
    padding-bottom: 0px !important; 
  }
  
#solutions .headboard .texto{
    margin-top:80px  !important;
    margin-bottom:50px  !important;
  }
@media screen and (max-width: 770px) { 
  
  #solutions .headboard .texto{
      margin-top:40px  !important;
      margin-bottom:10px  !important;
    }
  #solutions .headboard{
      text-align: center !important;
      background-position: top right !important;
    }
  
  }

/* ------------------------------------------- */
#solutions .sublock{
    min-height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: bottom center !important; 
}
#solutions .sublock .container-body-2{
     text-align: right;
}
@media screen and (max-width: 770px) { 
  #solutions .sublock .container-body-2{
    margin-top:-50px;
    text-align: center;
  }
  
}
/* ------------------------------------------- */
 #solutions .customCard{
  /* max-width: 890px; */
  margin: 20px 20px 20px 20px;
  padding: 10px 10px 10px 10px;
  background-color:#fff;
  color:#21005c;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
}
#solutions .customCard .texto{
  margin: 10px 0px 30px 0px;
}
#solutions .IconCustomCard{
  /* max-width: 890px; */
  margin: 20px 20px 20px 20px;
  padding: 25px 10px 10px 10px;
  background-color:#faf8ff;
  color:#21005c;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
}
#solutions .iconCard{
  width: 100px;
  height: 100px;
  display: block;
  margin-top:-85px;
  margin-bottom:10px;
  background-color: white;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#solutions .iconCardBody{
  padding: 10px 0px 0px 0px !important;
}

@media screen and (max-width: 770px) { 
  
  #solutions .iconCard{
    width: 80px;
    height: 80px;
    margin-top:-75px;
    margin-bottom:10px;
  }
  #solutions .iconCard img{
    width: 65px;
  }
  #solutions .iconCardBody{
    padding: 7px 0px 0px 2px !important;
  }
}

/* ------------------------------------------- */
#solution .videoMargin{
  margin-top: 50px;
}

#solutions .videoContainer{
  width: 400px;
  height: 227px;
  margin: -120px 0px -100px 80px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#solutions .videoContainer .embed-responsive-item{
margin:0px 0px 0px 0px;
}
@media screen and (min-width: 771px) and (max-width: 1030px) { 
  #solutions .videoContainer{
    width: 398px;
    height: 223px;
    margin: -100px 0px -90px -20px;
  }
#solutions .videoContainer .embed-responsive-item{
  width: 400px;
  height: 400px;
  margin:-90px 0px -0px 0px;
  }  
}
@media screen and (max-width: 770px) {
  #solution .videoMargin{
    margin-top: 0px;
  }
  #solutions .videoContainer{
    width: 280px;
    height: 159px;
    margin: 0px auto 0px auto;
  }
  #solutions .videoContainer .embed-responsive-item{
  width: 280px;
  height: 250px;
  margin:-45px 0px 0px 0px;
  } 
}

/* ------------------------------------------- */

.custom-list{
  padding: 0px 20px 0px 20px;
  color:#21005c;
}

/* ------------------------------------------- */
.tarjetas{
  color:#21005c;
  text-align: center;
  padding: 20px 0px 50px 0px;
}
@media screen and (min-width: 771px) and (max-width: 1030px) { 
  .tarjetas img{
    width: 350px;
   } 
}
@media screen and (max-width: 770px) {
  
 .tarjetas img{
  width: 250px;
 }

}

/* ------------------------------------------- */
.commerce{
  color:#21005c;
  text-align: left;
  padding: 20px 0px 50px 0px;
}
.commerce .card{
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.commerce .enchufe{
  text-align: right;
  margin-top: -200px;
}
.commerce .marca{
  padding: 0px 20px 0px 20px;
}
@media screen and (max-width: 770px) {
  .commerce .card{
    padding: 10px 30px 10px 30px;
  }
  .commerce .enchufe{
    display: none;
  }
  .commerce .marca{
    padding: 0px 10px 0px 10px;
  }
 
 }



